/**
 * Custom Component - Building History
 * @author Charles Harwood
 */
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
.building-history.row .field-history-1 {
  color: #000000;
}

@media (min-width: 1200px) {
  .building-history.row {
    margin-right: 0;
    margin-left: 0;
  }
  .building-history.row .field-history-1 {
    padding-right: 5rem;
    padding-left: 0;
  }
  .building-history.row .field-history-2 {
    margin-top: 1rem;
    padding-right: 0;
    padding-left: 5rem;
  }
}

.text-stack .building-history .field-history-1 {
  padding-right: 0;
}

.text-stack .building-history .field-history-2 {
  padding-left: 0;
}
